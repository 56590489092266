import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

const useStyles = makeStyles({ ...styles, ...imagesStyles });

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Featured designs</h2>
          <h5 className={classes.description}>
            Browse below to see our featured designs from some of the best
            afrocentric fashion designers.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer className={classes.listImg}>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                height: "360px",
                width: "100%",
                display: "block",
                backgroundImage: "url(/img/1.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className={classes.imgRounded}
              alt="Card-img-cap"
            ></div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                height: "360px",
                width: "100%",
                display: "block",
                backgroundImage: "url(/img/2.jpg)",
                backgroundSize: "cover",
              }}
              className={classes.imgRounded}
              alt="Card-img-cap"
            ></div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                height: "360px",
                width: "100%",
                display: "block",
                backgroundImage: "url(/img/3.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className={classes.imgRounded}
              alt="Card-img-cap"
            ></div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                height: "360px",
                width: "100%",
                display: "block",
                backgroundImage: "url(/img/4.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className={classes.imgRounded}
              alt="Card-img-cap"
            ></div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                height: "360px",
                width: "100%",
                display: "block",
                backgroundImage: "url(/img/5.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className={classes.imgRounded}
              alt="Card-img-cap"
            ></div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                height: "360px",
                width: "100%",
                display: "block",
                backgroundImage: "url(/img/6.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className={classes.imgRounded}
              alt="Card-img-cap"
            ></div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                height: "360px",
                width: "100%",
                display: "block",
                backgroundImage: "url(/img/7.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className={classes.imgRounded}
              alt="Card-img-cap"
            ></div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                height: "360px",
                width: "100%",
                display: "block",
                backgroundImage: "url(/img/8.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className={classes.imgRounded}
              alt="Card-img-cap"
            ></div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                height: "360px",
                width: "100%",
                display: "block",
                backgroundImage: "url(/img/9.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className={classes.imgRounded}
              alt="Card-img-cap"
            ></div>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <Button
              color="success"
              size="lg"
              href="https://www.instagram.com/afrocentrale?ref=afrocentrale"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-play" />
              See More
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
