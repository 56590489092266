import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import Home from "views/Home/Home.js";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

var hist = createBrowserHistory();

// const TRACKING_ID = "G-87CQMQJ0W8"; // YOUR_OWN_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: "G-87CQMQJ0W8",
};
TagManager.initialize(tagManagerArgs);

// hist.listen((location) => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// });
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/components" component={Components} />
      <Route path="/" component={Home} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
